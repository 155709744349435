import React from "react"

export default function WebProject({ project }) {
  return (
    <div className="web-project col-md-1-2">
      <h2 >{ project.projectTitle }</h2>
      { project.projectDescription.length > 0 &&
        <div className="project-description" dangerouslySetInnerHTML={{ __html: project.projectDescription }} />
      }
    </div>
  )
}