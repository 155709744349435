import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import WebProject from "../components/webProject"
import Seo from "../components/seo"

export default function Work({ data }) {
  return (
    <Layout title="work">
      <Seo
        title="Work"
      />
      <p>Web projects go here.</p>
      <div className="web-projects grid spaced">
        {data.craftApi.entry.webProjects.map(project => {
          return <WebProject key={project.id} project={project}></WebProject>
        })}
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    craftApi {
      entry {
        ... on CraftAPI_web_web_Entry {
          id
          webProjects {
            ... on CraftAPI_webProjects_project_BlockType {
              id
              role
              technology
              projectUrl
              projectTitle
              projectDescription
              githubRepo
              awards {
                col1
                awardTitle
                col2
                awardUrl
              }
              linkLabel
            }
          }
        }
      }
    }
  }
`